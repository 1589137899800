import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const dataUrl = 'https://gist.githubusercontent.com/tcyrus/e6b940b05e96cd35136a35872e7a8e2e/raw/friend_codes.json';

export default new Vuex.Store({
  state: {
    codes: [],
  },

  actions: {
    loadCodes({ commit }) {
      axios.get(dataUrl).then((response) => {
        commit('setCodes', response.data);
      });
    },
  },

  mutations: {
    setCodes(state, codes) {
      state.codes = codes;
    },
  },
});
