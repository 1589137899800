


































































import { mapState } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  computed: mapState(['codes']),
  data: () => ({
    headers: [
      { text: 'Name', align: 'left', value: 'name' },
      { text: 'Code', align: 'right', value: 'code' },
    ],
  }),
})
export default class App extends Vue {
  public created() {
    this.$store.dispatch('loadCodes');
  }

  public openRepo() {
    window.open('https://gitlab.com/tcyr.us/friends', '_blank');
  }

  public async copyText(text: string) {
    await navigator.clipboard.writeText(text);
  }
}
